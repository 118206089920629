import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, tap } from 'rxjs';
import { LocalStorageKeys, SnackBar } from '../helpers/constants.helper';
import { LocalstorageService } from './localstorage.service';

@Injectable()
export class ApiinterceptorService {
    constructor(private localStorageProvider: LocalstorageService, private matSnackBar : MatSnackBar) {}
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const jid = this.localStorageProvider.decryptAndGetData(
            LocalStorageKeys.JWT_TOKEN
        );
        let headers: any = {
            'Content-Type': 'application/json',
        };
        if (jid) {
            headers['Authorization'] = `Bearer ${jid}`;
            headers['Accept-Language'] = `en`;
        }
        req = req.clone({ setHeaders: headers });
        return next.handle(req).pipe(
            tap(
                () => {},
            )
        );
    }
}
