import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../helpers/constants.helper';
import { IUser } from '../helpers/types.helper';
import { HttpService } from './http.service';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user !: IUser | undefined;

  constructor(
    private localStorageProvider : LocalstorageService
  ) { }

  getUserDetails() : IUser | undefined{
    if(!this.user){
      const data = this.localStorageProvider.decryptAndGetData(LocalStorageKeys.USER_DATA);
      if(data) this.user = JSON.parse(data);
    }
    return this.user;
  }

  setUserDetails(user : IUser | undefined){
    if(user){
      this.localStorageProvider.encryptAndSetData(
        user,
        LocalStorageKeys.USER_DATA
      );
    }
  }

  removeUserDetails(){
    this.user = undefined;
  }

  setUserJwt(jwt: string){  
    this.localStorageProvider.encryptAndSetData(
      jwt,
      LocalStorageKeys.JWT_TOKEN
    );
  }
}
