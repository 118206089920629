import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'src/app/core/services/message.service';
import { UserService } from 'src/app/core/services/user.service';
import { Constants } from '../../../core/helpers/constants.helper';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    smallHeader: boolean = false;
    
    constructor(
        private messageServiceProvider : MessageService,
        private userServiceProvider : UserService
    ) {
        this.smallHeader = this.userServiceProvider.getUserDetails() ? true : false;
    }

    ngOnInit(): void {
        this.messageServiceProvider.isUserLoggedIn().subscribe((response:boolean) => {
            this.smallHeader = response;
        })
    }
}
