import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
export const LOCAL_STORAGE_SECRET = environment.localStorageSecret;

export const URL = environment.nodeServer;

export const PRODUCT_SKU = environment.productSKU;

export abstract class EndPoints {
    public static readonly countries = '/location/countries';
    public static readonly createUser = '/users/register';
    public static readonly loginUser = '/users/login';
    public static readonly verifyEmail = '/users/verify';
    public static readonly userBalance = '/users/balance';
    public static readonly getClientIp = '/location/current';
    public static readonly bidBot = '/auctions/:auctionId/bidbot';
    public static readonly auctionLogs = '/auctions/:auctionId/log';
    public static readonly productSKU = '/products/:productSKU';
    public static readonly currentAuction = '/auctions/current/:productSKU';
    public static readonly auctions = '/auctions';
    public static readonly resetPassword = '/users/reset-password';
}

export abstract class SocketChannels {
    public static readonly bidStatus = 'bid:status';
    public static readonly auctionResult = 'auction:result';
    public static readonly bidPublish = 'bid:publish';
    public static readonly bidCreate = 'bid:create';
    public static readonly ehlo = 'ehlo';
}

export abstract class RequestHeaders {
    public static readonly XRequestedWith = 'X-Requested-With';
    public static readonly XMLHttpRequest = 'XMLHttpRequest';
}

export abstract class LocalStorageKeys {
    public static readonly COUNTRY_LIST = 'country-list';
    public static readonly ACCESS_TOKEN = 'access-token';
    public static readonly USER_DATA = 'user-data';
    public static readonly JWT_TOKEN = 'jid';
    public static readonly PRODUCT_DATA = 'product-data';
    public static readonly AUCTION_DATA = 'auction-data';
    public static readonly AUCTION_WINNER = 'auction-winner';
}

export abstract class Path {
    public static readonly USER_MODULE = 'users';
    public static readonly AUCTIONS_MODULES = 'auctions';
    public static readonly HOME_PATH = 'home';
    public static readonly REGISTER_PATH = 'register';
    public static readonly LOGIN_PATH = 'login';
    public static readonly VERIFY_EMAIL_PATH = 'verify-email';
    public static readonly FORGET_PASSWORD_PATH = 'forgot-password';
    public static readonly LOGOUT_PATH = 'logout';
    public static readonly UPCOMING_AUCTIONS_PATH = 'upcoming-auctions';
    public static readonly BID_BOT_PATH = 'bid-bot';
    public static readonly BID_LOG_PATH = 'bid-logs';
    public static readonly BUY_PLAYS_PATH = 'buy-plays';
    public static readonly BUY_NOW_PATH = 'buy-now';
    public static readonly HELP_PATH = 'help';
    public static readonly AUCTIONS_LIST_PATH = 'auctions-list';
    public static readonly PROFILE_CALCULATION = 'profile-calculation';
    public static readonly AUCTION_STATUS_PATH = 'auction-status';
}

export abstract class Constants {
    // static resources
    public static readonly STATIC_CONTENT_URL =
        'https://static-content.theblockchain.team';
    public static readonly MERCEDES_THUMBNAIL =
        '../../../../assets/images/mercedes.png';
    public static readonly DIAMOND_THUMBNAIL =
        '../../../../assets/images/diamond_ring.png';
    public static readonly MERCEDES_VID =
        '../../../../assets/videos/mercdes.mp4';
    public static readonly DIAMOND_VID =
        '../../../../assets/videos/diamond_ring.mp4';
    public static readonly IPHONE_VID =
        '../../../../assets/videos/iphone-13-video.mp4';
    public static readonly BID_SOUND = '../../../../assets/sounds/bid.wav';
    public static readonly INTRO_GIF = '../../../../assets/videos/bigdeal01.gif';
    public static readonly WON_AUDIO = '../../../../assets/sounds/win.wav';
    public static readonly LOST_AUDIO = '../../../../assets/sounds/lost.wav';
}

export abstract class RouteUrl {
    public static readonly LOGOUT = `/${Path.AUCTIONS_MODULES}/${Path.LOGOUT_PATH}`;
    public static readonly HOME_MODULE = `/${Path.AUCTIONS_MODULES}/${Path.HOME_PATH}`;
    public static readonly REGISTER_MODULE = `/${Path.USER_MODULE}/${Path.REGISTER_PATH}`;
    public static readonly LOGIN_MODULE = `/${Path.USER_MODULE}/${Path.LOGIN_PATH}`;
    public static readonly VERIFY_EMAIL = `/${Path.USER_MODULE}/${Path.VERIFY_EMAIL_PATH}`;
    public static readonly FORGOT_PASSWORD = `/${Path.USER_MODULE}/${Path.FORGET_PASSWORD_PATH}`;
    public static readonly AUCTION_STATUS = `${RouteUrl.HOME_MODULE}/${Path.AUCTION_STATUS_PATH}`;
    public static readonly LOGOUT_MODULE = `/${Path.AUCTIONS_MODULES}/${Path.LOGOUT_PATH}`;
    public static readonly UPCOMING_AUCTIONS_MODULE = `/${Path.AUCTIONS_MODULES}/${Path.UPCOMING_AUCTIONS_PATH}`;
    public static readonly BID_BOT_MODULE = `/${Path.AUCTIONS_MODULES}/${Path.BID_BOT_PATH}`;
    public static readonly BUY_NOW_MODULE = `/${Path.AUCTIONS_MODULES}/${Path.BUY_NOW_PATH}`;
    public static readonly BID_LOG_MODULE = `/${Path.AUCTIONS_MODULES}/${Path.BID_LOG_PATH}`;
    public static readonly BUY_PLAYS_MOUDLE = `/${Path.AUCTIONS_MODULES}/${Path.BUY_PLAYS_PATH}`;
    public static readonly AUCTIONS_LIST_MODULE = `/${Path.AUCTIONS_MODULES}/${Path.BID_LOG_PATH}/${Path.AUCTIONS_LIST_PATH}`;
    public static readonly HELP_MODULE = `/${Path.AUCTIONS_MODULES}/${Path.HELP_PATH}`;
}

export abstract class Errors {
    public static readonly BID_FAILED = 'Bid Failed';
    public static readonly INSUFFICIENT_BALANCE = 'Insufficient Balance';
    public static readonly BID_PLACED = 'Bid already placed';
    public static readonly CHECK_PASSWORD = 'Please check your credentials';
    public static readonly BIDBOT_NOT_ABLE_TO_LOAD = 'Could not able to load bid bot';
    public static readonly INVALID_EMAIL = 'Invalid email address';
    public static readonly SOMETHING_WENT_WRONG = "Something went wrong";
}

export abstract class SnackBar {
    
    private static readonly options = new MatSnackBarConfig();

    public static getOptions(className : string = 'mat-new-style') {
        SnackBar.options.duration = 2500;
        SnackBar.options.verticalPosition = 'top';
        SnackBar.options.panelClass = className;
        return SnackBar.options;
    }
}