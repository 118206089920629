import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    private bidBotStatus = new EventEmitter<boolean>();
    private userLoggedIn = new EventEmitter<boolean>();
    private profitTable = new EventEmitter<boolean>();
    private shouldDisplay : boolean = false;


    constructor() {}

    bidBotStatusEvent(): EventEmitter<boolean> {
        return this.bidBotStatus;
    }

    isUserLoggedIn() : EventEmitter<boolean> {
        return this.userLoggedIn;
    }

    shouldShowProfit() : EventEmitter<boolean>{
        return this.profitTable;
    }
}
