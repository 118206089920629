import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';
import { LOCAL_STORAGE_SECRET } from '../helpers/constants.helper';
import { IAuctionDetails, ICountry, IProductDetails, IUser } from './../helpers/types.helper';

@Injectable({
    providedIn: 'root',
})
export class LocalstorageService {
    constructor() {}

    /**
     * @description encrypt data and store it in localstorage
     * @param  { [Countries] } data
     * @param key { }
     * @returns
     */
    encryptAndSetData(data: ICountry[] | IProductDetails | IUser | string | IAuctionDetails | any, key: string): boolean {
        if(data){
            let payload = data;
            if (typeof data == 'object') payload = JSON.stringify(data);
            const encryptedData = AES.encrypt(
                payload,
                LOCAL_STORAGE_SECRET
            ).toString();
            window.localStorage.setItem(key, encryptedData);
        }
        return true;
    }

    /**
     * @description get localstorage data
     * @param key localstorage key string
     */
    decryptAndGetData(key: string): string | undefined {
        const encryptedData = window.localStorage.getItem(key);
        if (encryptedData) {
            const decryptedData = AES.decrypt(
                encryptedData,
                LOCAL_STORAGE_SECRET
            );
            return decryptedData.toString(enc.Utf8);
        } else return undefined;
    }

    removeItem(key: string) {
        window.localStorage.removeItem(key);
    }
}
