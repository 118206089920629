<div class="mobile-frame">
    <div
        class="app-container d-flex flex-column height-100">
        <app-header></app-header>
        <div class="container-wrapper hide-scrollbar flex-1 mt-3">
            <router-outlet class="d-none"></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div>
