import { Component, OnInit } from '@angular/core';
import { RouteUrl } from 'src/app/core/helpers/constants.helper';
import { MessageService } from 'src/app/core/services/message.service';
import { UserService } from 'src/app/core/services/user.service';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    smfooter!: boolean;
    changeStatus!: boolean;
    routeTo = RouteUrl;
    private shouldDisplay : boolean = true;
    
    constructor(
        private messageServiceProvider: MessageService,
        private userServiceProvider: UserService
    ) {
        this.smfooter = this.userServiceProvider.getUserDetails()
            ? true
            : false;
    }

    ngOnInit(): void {
        this.messageServiceProvider
            .isUserLoggedIn()
            .subscribe((response: boolean) => {
                this.smfooter = response;
            });
        this.messageServiceProvider
            .bidBotStatusEvent()
            .subscribe((status) => (this.changeStatus = status));
    }

    displayProfitCalculation () {
        this.messageServiceProvider.shouldShowProfit().emit(this.shouldDisplay);
        this.shouldDisplay = !this.shouldDisplay;
    }
}
