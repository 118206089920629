<header class="app-header" *ngIf="!smallHeader">
    <div class="change-header d-flex flex-items-center">
        <div class="logo-container">
            <a href="#" class="logo"><img src="../../../../assets/images/logo-light-small.png" alt="Image"/></a>
        </div>
        <div class="header-tag-line font-din-pro">
            <span>Play Big | WIN BIG</span>
        </div>
    </div>
</header>
<header class="sm-app-header" *ngIf="smallHeader">
    <div class="sm-change-header d-flex flex-items-center">
        <div class="sm-logo-container">
            <a href="#" class="logo"><img src="../../../../assets/images/logo-light-small.png" alt="Image"/></a>
        </div>
        <div class="pl-1 header-tag-line font-din-pro">
            <span class="mr-2 pr-2">Play Big | WIN BIG</span>
        </div>
    </div>
</header>
